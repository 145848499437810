const configs = {
  prod: {
    GET_TOKEN_URL: 'https://api.amway.com.tw/bff/ci/anon/lc/getLoginToken'
  },
  qa: {
    GET_TOKEN_URL: 'https://api-qa.amwaynet.com.tw/bff/ci/anon/lc/getLoginToken'
  },
};

export default configs;
